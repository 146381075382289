import React, {useState, useEffect} from 'react';
import '../styles/services.scss';
import servicesList from '../services';
import Service from '../components/Service';

const ServicesPage = () => {
    const [services, setServices] = useState({});

    useEffect(() => {
        setServices(servicesList)
    },[])

    const AllServices = Object.keys(services).map((key) => (
        <Service
            key={key}
            imgService={services[key].img}
            title={services[key].title}
            text={services[key].text}
        />
    ))


    return (
      <div id="services">
        <h1>
          <img
            id="logo"
            src={require("../images/CREABAT_Logo.png")}
            alt="logo creabat renovations cholet"
          />
        </h1>
        <img
          id="bgServices"
          src={require("../images/banniere-services.png")}
          alt="fond maison creabat renovations cholet"
          style={{ width: "100%" }}
        />
        <div
          id="allServices"
          className="d-flex flex-row justify-content-around flex-wrap"
        >
          {AllServices}
        </div>
        <article>
          <h2>Tous nos corps de métiers</h2>
          <div id="metiersIcons">
            <div className="metier-icon">
          
                <img
                  src={require("../images/plombier-icon3@4x.png")}
                alt="plombier creabat renovations cholet"
                />
           
              <p>Plombiers</p>
            </div>
            <div className="metier-icon">
              <img
                src={require("../images/macon-icon2@4x.png")}
                alt="maçons creabat renovations cholet"
              />
              <p>Maçons</p>
            </div>
            <div className="metier-icon">
              <img
                src={require("../images/menuisier@4x.png")}
                alt="menuisiers creabat renovations cholet"
              />
              <p>Menuisiers</p>
            </div>
            <div className="metier-icon">
              <img
                src={require("../images/electricien-icon@4x.png")}
                alt="electriciens creabat renovations cholet"
              />
              <p>Electriciens</p>
            </div>
            <div className="metier-icon">
              <img
                src={require("../images/maitre-icon@4x.png")}
                alt="maitres d'oeuvres creabat renovations cholet"
              />
              <p>Maitres d'Oeuvres</p>
            </div>
          </div>
          <div id="collaborateurs">
            <h3 id="collaborateursTitle">Nous comptons aujourd'hui<br/> 14 collaborateurs/trices qualifiés/ées et expérimentés/ées</h3>
            <p style={{ fontWeight: "bold", color: "#ffffff", }}>à votre écoute pour satisfaire vos attentes.</p>
          </div>
         
        </article>
        <img
          id="camion"
          src={require("../images/camion-pub.png")}
          alt="camion creabat renovations cholet"
          style={{ width: "100%" }}
        />
      </div>
    );
}
 
export default ServicesPage;