import React, { createContext, useEffect, useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './fonts/Volte.otf';
import './styles/main.scss';
import './App.scss';
import { HashRouter, Switch, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import HeaderConstruct from './components/HeaderConstruct'
import FooterConstruct from './components/FooterConstruct'

// PAGES
import NotFound from './NotFound';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import ProjetsPage from './pages/ProjetsPage';
import AboutPage from './pages/AboutPage';
import ProjetPage from './pages/ProjetPage';
import ContactPage from './pages/ContactPage';
import PlanningPage from './pages/PlanningPage';
import EnConstructionPage from './pages/EnConstructionPage';
import Register from './pages/Register';

import { firebase } from './firebase/config'

import {UserContext} from './MyContexts'
import MentionsLegalesPage from './pages/MentionsLegalesPage';



function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [userSession, setUserSession] = useState(null)
  const [userData, setUserData] = useState({})




  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLoggedIn(true)
        setUserSession(user)
       

        if (!!userSession) {
          // Afficher les infos de l'utilisateur
          const usersRef = firebase.firestore().collection('users');
          usersRef
            .doc(userSession.uid)
            .get()
            .then(doc => {
              if (doc && doc.exists) {
                const myData = doc.data();
                setUserData(myData.id)
                //console.log('data user', myData)

              }

            })
            .catch(error => {
              console.log(error)
            })
          //console.log('userData', userData)

        }
      }
    })
    return () => {
      listener()
    }
  }, [userSession])

  return (
    <>
      <HashRouter>
        <Header />
        <Switch>
          <UserContext.Provider value={{ loggedIn, setLoggedIn }}>
            <Route exact path="/termesetconditions" component={MentionsLegalesPage} />
            <Route exact path="/planning" component={PlanningPage} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/projets/:id" component={ProjetPage} />
            <Route exact path="/projets" component={ProjetsPage} />
            <Route exact path="/services" component={ServicesPage} />
            <Route exact path="/" component={HomePage} />
          </UserContext.Provider>
          {/* <Route exact path="/" component={EnConstructionPage}/> */}
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
