import React, {useState, useEffect} from 'react';
import '../styles/about.scss';
import Membre from '../components/Membre';
import membersList from '../membres';

const AboutPage = () => {
    const [membres, setMembres] = useState({});

    useEffect(() => {
        setMembres(membersList)
    }, [])

    const AllMembers = Object.keys(membres).map((key) => (
        <Membre
            key={key}
            imgTrombi={membres[key].img}
            title={membres[key].title}
            text={membres[key].text}
            carte={membres[key].carte}
            alt={membres[key].carte}
            id={key}
        />
    ))

    return ( 
        <section id="about">
            <h1><img id="logo" src={require('../images/CREABAT_Logo.png')} alt="logo creabat renovations" /></h1>
            <h3 id="aproposTitle">A propos</h3>
            <article id="aboutSection">
                <div className="about-first-div"><img src={require('../images/team/team-creabat.png')} alt="l'équipe creabat renovations"/></div>
                <div className="about-first-div">
                    <p>Créée en 2019 par Rachid Houstani, l’entreprise intervient pour les constructions neuves ou en rénovation, chez les particuliers ou les professionnels. </p>
                    <p>Implantée à Cholet (49300), elle compte déjà plus de 15 chantiers à son actif.</p>
                    <p>Polyvalente et professionnelle, notre équipe d’experts est présente pour tous vos petits et gros travaux de rénovation, elle possède une expérience importante et des compétences développées dans les domaines de la maçonnerie, l’électricité, l’isolation et de la menuiserie… </p>
                    <p>Confiez nous vos projets de salles-de-bains, d’escalier ou de piscine. Nous nous adaptons à toutes vos demandes et à tous les projets à rénover.</p>
                    <p>Pour votre satisfaction, nous mettons à votre disposition un service personnalisé, avec 1 seul interlocuteur afin de faciliter nos échanges. Lors de notre entretien préalable, vous étudierez tous les conseils qui peuvent vous être utiles, nous nous adapteront à vos désirs. Ainsi, vous profiterez de notre expertise, nos compétences et notre sérieux durant les travaux.</p>
                    <p>Vous pouvez bien sûr nous contacter pour de plus amples renseignements ou pour une demande d’intervention ou un devis, nous le réaliserons gratuitement.</p>
                </div>
                
            </article>
            <article id="trombi">
                <h3 id="equipeTitle">L'équipe</h3>
                <div id="memberDiv" className="d-flex flex-wrap">
                    {AllMembers}
                </div>
                
            </article>
            
        </section>
     );
}
 
export default AboutPage;