import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyACqz2M23scFZNODDvUmEj7wGJ_0e57JFQ",
    authDomain: "creabat-884ac.firebaseapp.com",
    databaseURL: "https://creabat-884ac.firebaseio.com",
    projectId: "creabat-884ac",
    storageBucket: "creabat-884ac.appspot.com",
    messagingSenderId: "570864752936",
    appId: "1:570864752936:web:0fc7bde35a20be86d3581d",
    measurementId: "G-E58JNMJ17T"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}

export { firebase };