import React from 'react';
import '../styles/projets.scss';
import Projet from '../components/Projet';
import Nav from '../components/NavProjets';

const ProjetsPage = (props) => {
    return (
      <section id="projets">
        <h1>
          <img
            id="logo"
            src={require("../images/CREABAT_Logo.png")}
            alt="logo creabat renovations cholet"
          />
        </h1>
        <img
          id="bgProjets"
          src={require("../images/bgProjets.jpg")}
          alt="fond salon creabat renovations cholet"
          style={{ width: "100%" }}
        />
        <Nav />

        <Projet
          image="photopanop.jpg"
          description="Construction de maison sur mesure et clé en main, un seul interlocuteur. Maison sur 2 étages, avec 4 chambres, une salle de bain, une cuisine équipée et un garage."
          type="Construction de maison"
          imgSlide1="cuisine-slide.jpg"
          imgSlide2="IMG_6356-herbe.jpg"
          imgSlide3="salledebain.jpg"
        />
      </section>
    );
}
 
export default ProjetsPage;