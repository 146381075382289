import React from 'react';
import "../styles/home.scss";
import { Link } from "react-router-dom";
import { Carousel } from "3d-react-carousal";

const HomePage = () => {

    let slides = [
      <img
        src={require("../images/plans.jpg")}
        alt="plans de maisons creabat rénovations cholet travaux constructions"
        style={{ width: "100%" }}
        className="img-slide"
      />,
      <img
        src={require("../images/avant-apres4.jpg")}
        alt="avant rénovations après rénovations creabat cholet travaux constructions"
        style={{ width: "100%" }}
        className="img-slide"
      />,
      <img
        src={require("../images/bathroom.jpg")}
        alt="salles de bains neuves créabat rénovations cholet travaux constructions"
        style={{ width: "100%" }}
        className="img-slide"
      />,
      <img
        src={require("../images/cuisine-slide.jpg")}
        alt="cuisines neuves créabat rénovations cholet travaux constructions"
        style={{ width: "100%" }}
        className="img-slide"
      />
    ];

    return (
      <>
        <main id="home" style={{ position: "relative" }}>
          <section id="accueilimg">
            <h1>
              {/* creabat renovations cholet travaux constructions */}
              <img
                id="logo"
                src={require("../images/CREABAT_Logo.png")}
                alt="logo creabat renovations cholet travaux constructions"
                style={{ width: "55%", position: "absolute" }}
              />
            </h1>
            <img
              src={require("../images/mainBackground.jpg")}
              alt="background homepage creabat renovations cholet travaux constructions"
              style={{ width: "100%", zIndex: "1", marginTop: "-10px" }}
            />
            <div id="bgBlack"></div>
          </section>

          <section id="homeServices">
            <h2>nos services</h2>
            <article id="serviceDescription">
              <p>
                L’entreprise jeune et dynamique Creabat rénovations, vous
                propose tout son savoir-faire dans le domaine de la création et
                la rénovation immobilière.
              </p>
            </article>
            <article id="carousel" style={{paddingBottom: 50, paddingTop: 50, }}>
              <Carousel
                slides={slides}
                autoplay={true}
                interval={3000}
                style={{ width: "50%"}}
              />
            </article>
            <article id="serviceType" className="d-flex">
              <div>
                <img
                  src={require("../images/construction-maison.jpg")}
                  alt="plans de maisons creabat rénovations cholet travaux constructions"
                  style={{ width: "100%" }}
                />
                <h3>Constructions</h3>
              </div>
              <div>
                <img
                  src={require("../images/avant-apres2.jpg")}
                  alt="avant rénovations après rénovations creabat cholet travaux constructions"
                  style={{ width: "100%" }}
                />
                <h3>renovations</h3>
              </div>
              <div>
                <img
                  src={require("../images/piscine-steph.jpg")}
                  alt="maisons neuves créabat rénovations cholet travaux constructions"
                  style={{ width: "100%" }}
                />
                <h3>piscines</h3>
              </div>
            </article>
            <Link to="/services" className="btn btn-small m-4">
              Voir tous les services
            </Link>
          </section>

          <section id="projetsRecents">
            <h2>projets récents</h2>
            <div className="avant-apres d-flex flex-row justify-content-center mt-5">
              <iframe
                title="avantApres creabat renovations cholet travaux constructions"
                frameborder="0"
                class="juxtapose"
               
                src="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=b9a255b6-e705-11ea-bf88-a15b6c7adf9a"
              ></iframe>
            </div>

            
          </section>
          <section id="whyChoose">
            <h2>pourquoi choisir créabat ?</h2>
            <div id="whySection" className="d-flex">
              <img
                src={require("../images/maison-jardin.jpeg")}
                alt="maison renovations creabat cholet travaux constructions"
                id="homeGardenPic"
                style={{ borderRadius: "100%" }}
              />
              <article className="d-flex flex-column justify-content-center w-100 pl-5 pr-5 pb-5">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div>
                    <img
                      src={require("../images/ampoule.png")}
                      alt="expérience creabat renovations cholet travaux constructions"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>

                  <div className="d-flex flex-column p-3">
                    <h3 className="whyTitle">Expérience</h3>
                    <p>
                      Polyvalente et professionnelle, notre équipe d’experts est
                      présente pour tous vos petits et gros travaux, neuf et
                      rénovation. Force d’une grande expérience et compétences
                      développées dans les domaines de la maçonnerie,
                      l’électricité, l’isolation et de la menuiserie… .
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div>
                    <img
                      src={require("../images/clientele.png")}
                      alt="expérience creabat renovations cholet travaux constructions"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>
                  <div className="d-flex flex-column p-3">
                    <h3 className="whyTitle">Clientèle</h3>
                    <p>
                      L’entreprise intervient pour les constructions neuves ou
                      en rénovation, chez les particuliers ou les
                      professionnels. Déjà de nombreux chantiers sont à notre
                      actif. Tous les retours des clients qui nous ont déjà fait
                      confiance, sont positifs.
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div>
                    <img
                      src={require("../images/talk.png")}
                      alt="expérience creabat renovations cholet travaux constructions"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>
                  <div className="d-flex flex-column p-3">
                    <h3 className="whyTitle">Fiabilité</h3>
                    <p>
                      Pour votre satisfaction, nous mettons à votre disposition
                      un service personnalisé. Un seul interlocuteur afin de
                      faciliter nos échanges. Nos compétences et notre sérieux
                      vous accompagneront durant toute la durée des travaux.
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <Link to="/about" className="btn btn-small m-4 btn-about">
              En savoir plus
            </Link>
          </section>
        </main>
      </>
    );
}
 
export default HomePage;