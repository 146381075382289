import React, {useState, useEffect, useContext} from 'react';
import { UserContext } from '../MyContexts';
import { firebase } from "../firebase/config";

const Login = () => {
    const {loggedIn,setLoggedIn} = useContext(UserContext)
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    })


    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    const logIn = () => {
        firebase
          .auth()
          .signInWithEmailAndPassword(credentials.email, credentials.password)
          .then(res => {
            if (res.user) {
              setLoggedIn(true);
              setCredentials("");
            }
            console.log("l'utilisateur est connecté");
          })
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
          });
    }

    return (
      <form
        className="connexion d-flex flex-column align-items-center justify-content-center"
        onSubmit={logIn}
      >
        <h1>Connexion</h1>
        <div className="row text-center">
          <div className="col-sm-10">
            <input
              id="email"
              name="email"
              autoFocus
              onChange={handleChange}
              className="form-control-sm"
              placeholder="Votre Email"
            />
          </div>
          <div className="col-sm-10">
            <input
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
              className="form-control-sm"
              placeholder="Votre mot de passe"
            />
          </div>
        </div>
        <button
          type="submit"
          className="m-4 btn btn-danger"
        >
          Connexion
        </button>
      </form>
    );
}
 
export default Login;