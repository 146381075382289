const Membres = {
    membre1:{
        img: "marie-trombi.jpg",
        title: "Marie Sauce",
        text: "Relations Clients",
        carte: "Carte-visite-Marie.png"
    },
    membre2: {
        img: "team/Nathalie.png",
        title: "Nathalie Foulonneau",
        text: "Responsable Administrative",
        carte: "CREABAT_Logo.png"
    },
    membre3: {
        img: "Photo.png",
        title: "Nicolas Barbier",
        text: "Coordinateur de travaux",
        carte: "Carte-CREABAT-nicolas.png"
    },
    membre4: {
        img: "team/Wilfrid.png",
        title: "Wilfrid Perdriau",
        text: "Conducteur de travaux",
        carte: "Carte-CREABAT-Wilfrid.png"
    },
    membre5: {
        img: "team/Malik.png",
        title: "Malik Bacha",
        text: "Plombier-Chauffagiste-Electricien",
        carte: "CREABAT_Logo.png"
    },
    membre6: {
        img: "team/Charles.png",
        title: "Charles Retailleau",
        text: "Plombier",
        carte: "CREABAT_Logo.png"
        
    },
    membre7: {
        img: "team/Pavel.png",
        title: "Pavel Kosherov",
        text: "Maçon",
        carte: "CREABAT_Logo.png"
       
    },
    membre8: {
        img: "team/Joshua.png",
        title: "Joshua Renou",
        text: "Maçon",
        carte: "CREABAT_Logo.png"
       
    },
    membre9: {
        img: "team/Jean-phi.png",
        title: "Jean-Philippe Teixeira",
        text: "Maçon",
        carte: "CREABAT_Logo.png"
       
    },
    membre10: {
        img: "team/Dylan.png",
        title: "Dylan Migard",
        text: "Menuiserie-Placo-Isolation",
        carte: "Carte-visite-dylan.png"
    },
    membre11: {
        img: "team/Jonathan.png",
        title: "Jonathan Blasius",
        text: "Menuisier-Ebeniste",
        carte: "CREABAT_Logo.png"
    },
    membre12: {
        img: "team/Raphael.png",
        title: "Raphael Pinheiro",
        text: "Menuisier",
        carte: "CREABAT_Logo.png"
    },
    membre13: {
        img: "team/Hassan.png",
        title: "Hassan Sahlali",
        text: "Plaquiste",
        carte: "CREABAT_Logo.png"
    },

}

export default Membres;