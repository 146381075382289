import React from 'react';
import { Link } from "react-router-dom";
import '../styles/footer.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faMapMarkerAlt  } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {
    return ( 
        <div id="footer">
            <section id="redContact">
                <div className="d-flex flex-row justify-content-around align-items-center">
                    <div className="iconRed">
                    <FontAwesomeIcon icon={faMobileAlt} size="2x" style={{padding: "5px"}}/>
                    </div>
                    <p>07 88 50 48 66</p>
                </div>
                <div className="d-flex flex-row justify-content-around align-items-center" id="locationDiv">
                    <div id="mapInfos">
                        <iframe title="Adresse siège social creabat rénovations cholet" 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10871.706209724958!2d-0.886012!3d47.0612911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8ea6938966a9774!2sCreabat%20r%C3%A9novations!5e0!3m2!1sfr!2sfr!4v1649771448401!5m2!1sfr!2sfr" 
                            width="100%" height="100" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        <p>115 rue Maindron</p>
                        <p>49300 Cholet</p>
                    </div>
                    <div className="iconRed">
                        <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" style={{ padding: "5px" }}/>
                    </div>
                    <p className="text-white situer"> Nous situer </p>
                </div>
                
                <div className="d-flex flex-row justify-content-around align-items-center">
                    <div className="iconRed">
                        <FontAwesomeIcon icon={faEnvelope} size="2x" style={{ padding: "7px" }}/>
                    </div>
                    <p><Link to="/contact" className="text-white">Demandez un devis</Link></p>
                </div>
            </section>
            <section id="copyrigths">
                <p>©Copyrigths 2020 Créabat | <Link to="/termesetconditions">Termes et conditions</Link> | Réalisé par <a href="www.decibel-artweb.com" id="decibel">Décibel Artweb</a> </p>

            </section>
        </div>
     );
}
 
export default Footer;