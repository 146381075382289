import React from 'react';
import '../styles/contact.scss';
import ContactForm from '../components/ContactForm'

const ContactPage = () => {
    return ( 
        <div id="contact">
            <h1><img id="logo" src={require('../images/CREABAT_Logo.png')} alt="logo creabat renovations" /></h1>
            <img id="bgContact" src={require('../images/background-contact.png')} alt="téléphone contact creabat renovations" />
            <ContactForm/>
            <iframe title="Adresse siège social" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10871.706209724958!2d-0.886012!3d47.0612911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc8ea6938966a9774!2sCreabat%20r%C3%A9novations!5e0!3m2!1sfr!2sfr!4v1649771448401!5m2!1sfr!2sfr" width="100%" height="500" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
     );
}
 
export default ContactPage;