const services = {
  service1: {
    img: "construction-maison.jpg",
    title: "constructions",
  },
  service2: {
    img: "avant-apres2.jpg",
    title: "rénovations",
  },
  service3: {
    img: "cuisine-slide.jpg",
    title: "cuisines",
    text:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. In natus facere rem dolorum quos"
  },
  service4: {
    img: "bathroom.jpg",
    title: "salles de bains",
    text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea atque temporibus assumenda? "
  },
  service5: {
    img: "jardins.jpg",
    title: "jardins",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, veritatis temporibus dolorum!"
  },
  service6: {
    img: "piscine-steph.jpg",
    title: "piscines",
    text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro deserunt voluptas maiores!"
  }
};

export default services;