import React from 'react';
import { Link } from "react-router-dom";
import '../styles/header.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane}from '@fortawesome/free-regular-svg-icons';

const Header = () => {
    return ( 
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ zIndex: "9999", position: "absolute", width: "100%", top: "0"}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <Link to="/" >Accueil <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <Link to="/services">Services <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <Link to="/projets">Projets <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <Link to="/about">A propos <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <Link to="/contact"><FontAwesomeIcon icon={faPaperPlane} size="1x"/> Demandez un devis <span className="sr-only">(current)</span></Link>
                    </li>
                </ul>
            </div>
            <div id="socialNetwork">
                <a href="https://instagram.com/creabatrenovations?igshid=10xvhssqeftlp" target="_blank"
                    rel="noopener noreferrer">
                    <img className="logosNetwork" src={require('../images/instagram-sketched.png')} alt="lien instagram creabat renovations cholet"/>
                </a>
                <a href="https://www.facebook.com/CreabatRenovations" target="_blank"
                    rel="noopener noreferrer">
                    <img className="logosNetwork" src={require('../images/facebookw.png')} alt="lien facebook creabat renovations cholet" />
                </a>
            </div>
        </nav>
     );
}
 
export default Header;