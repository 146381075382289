import React, {useEffect, useState, useContext} from 'react';
import '../styles/planning.scss'

import Login from '../components/Login'
//Firebase:
import { firebase } from "../firebase/config";
import LoadingFile from '../components/LoadingFile';
import { UserContext } from '../MyContexts';

const PlanningPage = () => {
   const {loggedIn, setLoggedIn} = useContext(UserContext)
    console.log("Utilisateur connecté ?", loggedIn)
   

    const renderPlanning = () =>{
       if (loggedIn) {
           return (
               <LoadingFile/>
           )
       } else {
           return <Login/>

       }
    }

    const logOut = () =>
    {
        firebase
          .auth()
          .signOut()
          .then(function() {
           setLoggedIn(false)
           console.log("vous êtes déconnecté")
          })
          .catch(function(error) {
            // An error happened.
          });
    }
   
    return (
      <section id="planning">
        <h1>Planning</h1>
        <div className="m-4 btn btn-danger" onClick={logOut}>
          Déconnexion
        </div>
        <article>{renderPlanning()}</article>
      </section>
    );
}
 
export default PlanningPage;