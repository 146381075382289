import React from 'react';

const Service = ({ imgService, title}) => {
    return ( 
        <div className="serv">
            <div className="img-serv">
                <img src={require(`../images/${imgService}`)} alt={imgService + " creabat renovations"} />
            </div>
            <h3>{title}</h3>
            <span className="bg"></span>
        </div>
     );
}
 
export default Service;