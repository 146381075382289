import React, { useState, useEffect, useContext } from 'react';

//Firebase:
import { firebase } from "../firebase/config";
import { UserContext } from '../MyContexts';

const Register = (props) => {
    const {setLoggedIn} = useContext(UserContext); 
    
    const [error, setErrors] = useState("");
  
    const [credentials, setCredentials] = useState({
        displayName: "",
        email: "",
        password: "",
        confirmPassword: ""
    })

    

    const handleChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;
        setCredentials({ ...credentials, [name]: value });

    }

    const onRegisterPress = (e) => {
        e.preventDefault();
        console.log([credentials.email, credentials.password]);
         if (credentials.password !== credentials.confirmPassword) {
            console.log("Le mot de passe n'est pas correct")
            return
        }
        firebase
            .auth()
            .createUserWithEmailAndPassword(credentials.email, credentials.password)
            .then(res => {
                if (res.user) {
                    setLoggedIn(true);
                    setCredentials("");
                }
                console.log("l'utilisateur est enregistré")

            })
            .catch(e => {
                setErrors("le mot de passe doit contenir au moins 6 charactères");
                console.log("Une erreur est survenue")
            });
       
       
       
    }
    return (
      <div className="mt-5">
        <h1>Inscription</h1>
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={onRegisterPress}
        >
          <div className="row text-center">
            <div className="col-sm-10">
              <input
                type="text"
                id="displayName"
                name="displayName"
                onChange={handleChange}
                value={credentials.displayName}
                className="form-control-sm"
                placeholder="Votre Nom"
              />
            </div>
            <div className="col-sm-10">
              <input
                type="email"
                id="email"
                name="email"
                autoFocus
                onChange={handleChange}
                value={credentials.email}
                className="form-control-sm"
                placeholder="Votre Email"
              />
            </div>
            <div className="col-sm-10">
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                value={credentials.password}
                className="form-control-sm"
                placeholder="Votre mot de passe"
              />
            </div>
            <div className="col-sm-10">
              <input
                type="text"
                id="confirmPassword"
                name="confirmPassword"
                onChange={handleChange}
                value={credentials.confirmPassword}
                className="form-control-sm"
                placeholder="Confirmez le mot de passe"
              />
            </div>
          </div>
          <p style={{color: "red", fontSize: "1rem",}}>{error}</p>
          <button type="submit" className="m-4 btn btn-danger">
            Enregistrer
          </button>
        </form>
      </div>
    );
}

export default Register;