import React, {useState} from 'react';
import { Link } from "react-router-dom";

const NavProjets = () => {
    const [activation, setActivation] = useState({
        projet1: "",
        projet2: "",
        projet3: "",
    });
    const [isActivate, setIsActivate] = useState(false)

    const activeBtn = () => {
        setActivation(" active")
        setIsActivate(true)
    }
    return ( 
        <ul className="nav justify-content-around" id="navProject">
            <li className="nav-item">
                <Link className={"nav-link " + activation.projet1} to="/projets/1" onClick={activeBtn}>Projet n°1</Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link " + activation.projet2} to="/projets/2" onClick={activeBtn}>Projet n°2</Link>
            </li>
            <li className="nav-item">
                <Link className={"nav-link " + activation.projet3} to="/projets/3" onClick={activeBtn}>Projet n°3</Link>
            </li>
        </ul>

     );
}
 
export default NavProjets;