import React, {useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const Membre = ({imgTrombi, title, text, carte, alt, id}) => {
    const [isToggle, setIsToggle] = useState(false);
    const toggleTrueFalse = () => setIsToggle(!isToggle);

    const [show, setShow] = useState(true);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return ( 
        <div className="membre">
            <img className="img-trombi" src={require(`../images/${imgTrombi}`)} alt={imgTrombi + " creabat renovations"}/>
            <div className="d-flex flex-column align-items-center">
                <h4>{title}</h4>
                <p style={{margin: "0px"}}>{text}</p>
            </div>
            

            {isToggle && (
                <Modal
                    show={show}
                    onHide={toggleTrueFalse}
                    className="w-100"
                    size="lg"
                    style={{ paddingTop: "250px", zIndex: "9999" }}
                >
                    <Modal.Body style={{ margin: "auto", textAlign: "center" }}>
                        {carte ? 
                            <span className="fiche">
                                <img src={require('../images/' + carte)} alt={alt} id={id} style={{ width: "100%" }} />
                            </span>  
                        : " "}
                       

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleTrueFalse}> Fermer </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <span className="bg">
                <button className="btn btn-outline-light" onClick={toggleTrueFalse}>
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" className="text-white" />
                </button>
            </span>

        </div>
     );
}
 
export default Membre;