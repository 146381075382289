import React from 'react';

const Projet = ({ image, description, type, imgSlide1, imgSlide2, imgSlide3}) => {
    return ( 
        <section id="chantier">
            <article id="ficheDescript">
                <div className="m-4" id="ficheChantier">
                    <h3>{type}</h3>
                    <img src={require(`../images/${image}`)} alt={image + " creabat renovations"}/>
                </div>
                <div className="m-4" id="descript">
                    <h3 className="text-right">description</h3>
                    <p>{description}</p> 
                </div>
            </article>
            <article id="galeryPrice">
                <div className="m-4" id="galeryCarousel">
                    <h3>Galerie</h3>
                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={require(`../images/${imgSlide1}`)} className="d-block w-100" alt={imgSlide1 + " renovation creabat"}/>
                            </div>
                            <div className="carousel-item">
                                <img src={require(`../images/${imgSlide2}`)} className="d-block w-100" alt={imgSlide2 + " renovation creabat"}/>
                            </div>
                            <div className="carousel-item">
                                <img src={require(`../images/${imgSlide3}`)} className="d-block w-100" alt={imgSlide3 + " renovation creabat"}/>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
               
            </article>
        </section>
     );
}
 
export default Projet;