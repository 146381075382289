import React from 'react';
import '../styles/projets.scss';
import Projet from '../components/Projet';
import Nav from '../components/NavProjets';

const ProjetsPage = (props) => {
    return (
      <section id="projets">
        <h1>
          <img
            id="logo"
            src={require("../images/CREABAT_Logo.png")}
            alt="logo creabat renovations cholet"
          />
        </h1>
        <img
          id="bgProjets"
          src={require("../images/bgProjets.jpg")}
          alt="fond salon creabat renovations cholet"
          style={{ width: "100%" }}
        />
        <Nav />

        {props.match.params.id === "1" && (
          <Projet
            image="photopanop.jpg"
            description="Construction de maison sur mesure et clé en main, un seul interlocuteur. Maison sur 2 étages, avec 4 chambres, une salle de bain, une cuisine équipée et un garage."
            type="Construction de maison"
            imgSlide1="cuisine-slide.jpg"
            imgSlide2="IMG_6356-herbe.jpg"
            imgSlide3="salledebain.jpg"
          />
        )}

        {props.match.params.id === "2" && (
          <Projet
            image="renovation.jpg"
            description="Maison entièrement rénovée. La façade et le jardin ont été entièrement refaits ainsi que toutes les pièces à l'intérieur comme la cuisine, la salle de bain et les chambres."
            type="Renovation d'une maison"
            imgSlide1="avant.png"
            imgSlide2="cuisine2.jpg"
            imgSlide3="sdb2.jpg"
          />
        )}

        {props.match.params.id === "3" && (
          <Projet
            image="piscine-steph.jpg"
            description="Confiez nous votre projet de piscine. Nous réalisons tout de A à Z. "
            type="construction d'une piscine"
            taille="2X110m2"
            prix="200 000€"
            imgSlide1="piscine2.jpg"
            imgSlide2="piscine1.jpg"
            imgSlide3="piscine4.jpg"
          />
        )}
      </section>
    );
}

export default ProjetsPage;