import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Formulaire = () => {
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setPhoneNumber({ ...phoneNumber, [name]: value });
  };
  const sendEmail = e => {
    e.preventDefault();
    if (phoneNumber.user_phone === "") {
      setIsValid(true);
      setError("Vous devez remplir tous les champs !");
      console.log("le champs est vide");
    } else {
      setError("");
      setIsValid(false);
      emailjs
        .sendForm(
          "smtp_server_lws",
          "creabat",
          e.target,
          "user_Yfwte56tv9XV8POhlSI3x"
        )
        .then(
          result => {
            toast.success("Votre email a bien été envoyé !");
            console.log(result.text);
          },
          error => {
            setError("Vous devez remplir tous les champs !");
            toast.error(
              "Une erreur est survenue, votre email n'a pas pu être envoyé. Veuillez recommencer."
            );
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };

  return (
      <div className="formBlock">
          
                <form onSubmit={sendEmail} id="formSection">
        <img id="logo" src={require('../images/CREABAT_Logo.png')} alt="logo creabat renovations cholet" style={{ width: "200px" }} />
                    <ToastContainer />
                    <input type="hidden" name="contact_number"></input>
                    <div className="form-group">
                    <label>
                        <input type="text" name="user_name" className="form-control" placeholder="Nom et prénom"/>
                    </label>
                    </div>
                    <div className="form-group">
                    <label>
                        <input type="text" name="user_email" className="form-control" placeholder="Email"/>
                    </label>
                    </div>
                    <div className="form-group">
                    <label>
                        <input type="text" name="user_phone" className="form-control" placeholder="Téléphone" onChange={handleChange} />
                    </label>
                    </div>
                    <label>
                        <textarea type="text" name="message_html" className="form-control" placeholder="Message" required/>
                        {error}
                    </label>
                    <label>
                        <input type="submit" className="btn btn-danger" value="Envoyer"/>
                    </label>

                </form>
                
            </div>
  );
};

export default Formulaire;
